<section class="banner-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container-fluid">
        <div class="row align-items-center" style="background: rgba(132, 67, 153, 0.05);">
          <div class="col-lg-6">
            <div class="banner-content">
              <h1>Trusted IT Services to Empower Your Business Success</h1>
              <p>Our Isthara smart tech provides trusted and reliable solutions that empower your business to succeed in
                today's digital age. We offer a wide range of services, including,
                <b>UI/UX Design, cloud computing, software development</b>. With our comprehensive IT solutions, you can
                streamline your operations, enhance your productivity, and stay ahead of the competition.
              </p>
              <div class="banner-btn">
                <a routerLink="/contact" class="contact-btn">Contact Us</a>
                <a routerLink="/about" class="border-btn">Know More</a>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="banner-img">
              <img src="../../../../assets/images/home/banner-image.svg" alt="banner-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="home-shape">
    <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
    <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
    <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
    <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
    <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
    <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
  </div> -->
</section>

<section class="about-us-area mt-5 pt-100 pb-70">
  <div class="container">
    <div class="row d-flex justify-content-center">
      <div class="col-lg-4 col-sm-6 home-about-us">
        <div class="banner-content">
          <span class="about-us">About Us</span>
          <h2>Achieving Excellence in the Digital Age</h2>
          <p>Isthara Tech Solutions is a leading provider of IT solutions, committed to delivering exceptional digital
            innovations that enable our clients to achieve outstanding results. Our mission and promise are to craft
            custom software that empowers forward-thinking brands to succeed in the digital realm.</p>
          <p>We firmly believe that our clients deserve more, which is why we provide a genuine software partnership
            that is dedicated to realizing their objectives through cutting-edge digital products. Our focus is on
            collaborating with our clients to help them achieve their goals and drive their businesses forward.</p>
          <div class="banner-btn">
            <a routerLink="/about" class="contact-btn">Know More</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6 home-abous-us">
        <div class="about-us-img">
          <img src="../../../../assets/images/home/about-us-img.png" alt="banner-img" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="home-service-area pt-70 pb-70">
  <div class="container">
    <div class="section-title">
      <span>Isthara Smart Tech Services</span>
      <h2>Isthara Provide All Kind of Tech Solutions</h2>
      <p>Our team will leverage the latest technologies and industry best practices to build a solution that addresses
        your unique business challenges and drives growth.</p>
    </div>

    <div class="row services-box-container">
      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/home/UI-Ux.svg" alt="UI/UX" />
          </div>

          <div class="service-content">
            <h3>UI/UX Design</h3>
            <p>We provide UX-focused product ideation and design services, using a comprehensive approach that includes
              brainstorming, design thinking, UX research, and product strategy to create engaging UI designs aligned
              with our clients' goals.
            </p>
            <a routerLink="/solutions-details" class="line-bnt">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/home/web-development.svg" alt="Web Development" />
          </div>

          <div class="service-content">
            <h3>Web Development</h3>
            <p>Our web development services provide tailored solutions for our clients, utilizing a user-centered
              approach that includes strategy, design, and development. We use the latest technology and best practices
              to build engaging, fully functional websites that are optimized for speed, security, and search engine
              visibility.
            </p>
            <a routerLink="/solutions-details" class="line-bnt">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/home/qa-testing.svg" alt="QA Testing" />
          </div>

          <div class="service-content">
            <h3>QA Testing</h3>
            <p>Our QA testing service ensures high-quality products, including web and mobile apps, through rigorous
              testing of functionality, usability, security, and performance. We use industry-standard testing
              methodologies for efficient and comprehensive results. Our approach enables us to identify and resolve
              issues quickly.
            </p>
            <a routerLink="/solutions-details" class="line-bnt">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/home/mobile.svg" alt="Mobile App" />
          </div>

          <div class="service-content">
            <h3>Mobile App Development</h3>
            <p>Our mobile app development service provides customized solutions to meet our clients' needs, utilizing a
              user-centered approach that includes strategy, design, and development. We use the latest technology and
              best practices to build engaging, fully functional mobile apps that are optimized for performance and
              security.
            </p>
            <a routerLink="/solutions-details" class="line-bnt">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/home/devops.svg" alt="DevOps" />
          </div>

          <div class="service-content">
            <h3>DevOps</h3>
            <p>we partner as your trusted advisors to assess current state with respect to cloud & DevOps, Recommend the
              most valuable path, and guide throughout the transformation journey. we provide end to end cloud
              professional services across the azure, aws, & GCP. we build manage, optimize the cloud infrastructure and
              solutions. rephrase this sentence into short
            </p>
            <a routerLink="/solutions-details" class="line-bnt">Connect Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="home-company-area bg-color">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="company-content">
          <div class="company-tittle">
            <span>Our Company</span>
            <h2>Innovative It Helping Service All Over the World</h2>
            <p>It is a long established fact that a reader will be distracted by the rea dable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more or less normal distribution of
              letters, as opposed to using Content here,content here normal distribution looking at its.</p>
            <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
              in some form variations passages.</p>
          </div>

          <a routerLink="/about" class="box-btn">Know More</a>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="company-img">
          <img src="assets/images/company/company-img.jpg" alt="company" />
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="home-process-area pt-100 pb-70">
  <div class="container">
    <div class="section-title">
      <span>Working Process</span>
      <h2>We are popular because of our way of working</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Quis ipsum suspendisse.</p> -->
    </div>

    <div class="row">
      <div class="col-lg-3 col-sm-6">
        <div class="single-process">
          <div class="icon">
            <img src="../../../../assets/images/home/research_icon.svg" alt="Research Product" />
            <span><img src="../../../../assets/images/home/arrow-icon.png" alt="Arrow" /></span>
          </div>

          <div class="content">
            <h3>Research Product</h3>
            <p>We understand your business, identify pain points, and design tailored solutions that meet your goals.we
              strive to gain a deep understanding of your needs and goals.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-process">
          <div class="icon">
            <img src="../../../../assets/images/home/user_testing.svg" alt="User Testing" />
            <span class="pro-span"><img src="../../../../assets/images/home/arrow-icon.png" alt="Arrow" /></span>
          </div>

          <div class="content">
            <h3>User Testing</h3>
            <p>During this phase, we work with real users to ensure that our solution is easy to use, intuitive, and
              meets their needs.This allows us to identify pain points and areas for improvement.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-process">
          <div class="icon">
            <img src="../../../../assets/images/home/web_icon.svg" alt="Development" />
            <span><img src="../../../../assets/images/home/arrow-icon.png" alt="Arrow" /></span>
          </div>

          <div class="content">
            <h3>Development</h3>
            <p>We use our extensive industry knowledge and expertise to develop. Our team works with the latest
              technologies to ensure that your solution is not only functional, but also scalable and reliable.</p>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-6">
        <div class="single-process">
          <div class="icon">
            <img src="../../../../assets/images/home/product_handware.svg" alt="Product Handover" />
          </div>

          <div class="content">
            <h3>Product Handover</h3>
            <p>We move on to the product handover phase. This is where we transition your custom solution from our
              development to live. we work closely with your team to ensure a smooth and seamless transition.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="home-team-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Choose Isthara tech solutions</span>
      <h2>We Achieved People’s Trust by Our Great Service</h2>
      <p>At Isthara smart tech solution, our mission is to provide exceptional service that not only meets but exceeds
        the expectations of our users. we have earned their trust and built a strong relationship based on mutual
        respect and understanding.</p>
    </div>
    <div class="home-team-slider">
      <!-- <owl-carousel-o [options]="teamSlides"> -->
      <!-- <ng-template carouselSlide> -->
      <div class="single-team">
        <div class="team-img">
          <img src="../../../../assets/images/home/understanding.svg" alt="Understanding" />
        </div>

        <div class="content text-center">
          <h3>Understanding</h3>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="single-team">
        <div class="team-img">
          <img src="../../../../assets/images/home/best_quality.svg" alt="Best Quality" />
        </div>

        <div class="content text-center">
          <h3>Best Quality</h3>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="single-team">
        <div class="team-img">
          <img src="../../../../assets/images/home/technology.svg" alt="Technology" />
        </div>

        <div class="content text-center">
          <h3>Technology</h3>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="single-team">
        <div class="team-img">
          <img src="../../../../assets/images/home/support.svg" alt="Support" />
        </div>

        <div class="content text-center">
          <h3>Support 24/7</h3>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="single-team">
        <div class="team-img">
          <img src="../../../../assets/images/home/expert_team.svg" alt="Expert" />
        </div>

        <div class="content text-center">
          <h3>Expert Team</h3>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- </owl-carousel-o> -->
    </div>
  </div>
</section>

<!-- <section class="home-case ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Choose Isthara tech solutions</span>
      <h2>We Achieved People’s Trust by Our Great Service</h2>
      <p>At our company, our mission is to provide exceptional service that not only meets but exceeds the expectations
        of our users. we have earned their trust and built a strong relationship based on mutual respect and
        understanding.</p>
    </div>
    <div class="case">
      <ngx-tabset>
        <ngx-tab tabTitle="All">
          <div class="row case-list">
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c1.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Joe’s Company Software Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Ride Share App UX Studies & Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Restaurant Management & Web Developing</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>IT Software Company Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Parking Management & Web Developing</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Temperature App UX Studies & Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
          </div>
        </ngx-tab>
        <ngx-tab tabTitle="Design">
          <div class="row case-list">
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>IT Software Company Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Parking Management & Web Developing</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Temperature App UX Studies & Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
          </div>
        </ngx-tab>
        <ngx-tab tabTitle="Development">
          <div class="row case-list">
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c2.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Ride Share App UX Studies & Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c3.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Restaurant Management & Web Developing</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>IT Software Company Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
          </div>
        </ngx-tab>
        <ngx-tab tabTitle="Cyber Security">
          <div class="row case-list">
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Temperature App UX Studies & Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>IT Software Company Development Case</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6">
              <div class="single-case">
                <div class="case-img">
                  <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg" alt="case" /></a>
                </div>

                <div class="content">
                  <a routerLink="/case-studies-details">
                    <h3>Parking Management & Web Developing</h3>
                  </a>
                  <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi smod tempor
                    incididunt ipsum dolor.</p>
                  <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                </div>
              </div>
            </div>
          </div>
        </ngx-tab>
      </ngx-tabset>
    </div>
    <div class="case-btn text-center">
      <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
    </div>
  </div>
</section>

<section class="feature-area bg-color ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="contnet">
          <div class="feature-tittle">
            <span>Features</span>
            <h2>We Have Also Some Features That Provided by Aiva</h2>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt consectetur, beatae quod eaque
              reprehenderit non ab quibusdam doloribus voluptatibus! Voluptatum aspernatur quasi id dolore doloremque
              quo vero</p>
          </div>
          <ul>
            <li><i class="flaticon-correct"></i> Freelancing Traning Course</li>
            <li><i class="flaticon-correct"></i> Technological Consultation</li>
            <li><i class="flaticon-correct"></i> Monthly Paid Workspace</li>
            <li><i class="flaticon-correct"></i> IT Learning Institute</li>
            <li><i class="flaticon-correct"></i> Digital Marketing</li>
          </ul>
          <a routerLink="/contact" class="box-btn">Lets Talk!</a>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="feature-img">
          <img src="assets/images/feature-img.png" alt="feature" />
        </div>
      </div>
    </div>
  </div>
</section> -->

<!-- <section class="client-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Testimonials</span>
      <h2>What Our Client’s Say</h2>
    </div>
    <div class="client-wrap">
      <owl-carousel-o [options]="clientWrap">
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="../../../../assets/images/home/client-1.png" alt="Client">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum
              dolor sit amet,</p>
            <h3>Omit Jacson</h3>
            <span>Company Founder</span>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="single-client">
            <img src="../../../../assets/images/home/client-2.png" alt="Client">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum
              dolor sit amet,</p>
            <h3>Steven Jony</h3>
            <span>CEO of Company</span>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</section> -->

<section class="choose-area ptb-100" style="padding-bottom: 20px !important;">
  <div class="container">
    <div class="section-title">
      <span>Team Members</span>
      <h2>People Who Are Behind The Achievements</h2>
      <p>At Isthara smart tech solution, we believe that our achievements are the result of the hard work and dedication
        of our team members.</p>
    </div>

    <div class="row home-team-slider">
      <!-- <owl-carousel-o [options]="teamSlides"> -->
      <!-- <ng-template carouselSlide> -->
      <div class="col-lg-3 col-md-12 team-member">
        <div class="team-img">
          <img src="../../../../assets/images/home/godwin.svg" alt="Team" />
        </div>

        <div class="content col-md-12 text-center">
          <h3>Ligouri Godwin</h3>
          <p>Head Of Business</p>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="col-lg-3 col-md-12 team-member">
        <div class="team-img">
          <img src="../../../../assets/images/home/gaurav.svg" alt="Team" />
        </div>

        <div class="content text-center">
          <h3>Gaurav Sharma</h3>
          <p>CTPO</p>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="col-lg-3 col-md-12 team-member">
        <div class="team-img">
          <img src="../../../../assets/images/home/chaitnya.svg" alt="Team" />
        </div>

        <div class="content text-center">
          <h3>Chaitanya V</h3>
          <p>Director of Engineering</p>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- <ng-template carouselSlide> -->
      <div class="col-lg-3 team-member">
        <div class="team-img">
          <img src="../../../../assets/images/home/prateek.svg" alt="Team" />
        </div>

        <div class="content text-center">
          <h3>Prateek Pandey</h3>
          <p>Vice President of Engineering</p>
        </div>
      </div>
      <!-- </ng-template> -->
      <!-- </owl-carousel-o> -->
    </div>
  </div>
</section>

<!-- <section class="home-blog-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Blog Post</span>
      <h2>Our Regular Blogs</h2>
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis
        exercitationem incidunt, alias corporis quam assumenda dicta.</p>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <a routerLink="/blog-details"><img src="assets/images/blog/1.jpg" alt="blog"></a>
          </div>

          <div class="content">
            <ul>
              <li>10 April 2020</li>
              <li><a routerLink="/blog">By Admin</a></li>
            </ul>
            <a routerLink="/blog-details">
              <h3>Joe’s Company Software Development Case</h3>
            </a>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam
              aspernatur porro</p>
            <a routerLink="/blog-details" class="line-bnt">Read More</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="single-blog">
          <div class="blog-img">
            <a routerLink="/blog-details"><img src="assets/images/blog/5.jpg" alt="blog"></a>
          </div>

          <div class="content">
            <ul>
              <li>10 April 2020</li>
              <li><a routerLink="/blog">By Admin</a></li>
            </ul>
            <a routerLink="/blog-details">
              <h3>Temperature App UX Studies & Development Case</h3>
            </a>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam
              aspernatur porro</p>
            <a routerLink="/blog-details" class="line-bnt">Read More</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
        <div class="single-blog">
          <div class="blog-img">
            <a routerLink="/blog-details"><img src="assets/images/blog/3.jpg" alt="blog"></a>
          </div>

          <div class="content">
            <ul>
              <li>10 April 2020</li>
              <li><a routerLink="/blog">By Admin</a></li>
            </ul>
            <a routerLink="/blog-details">
              <h3>IT Software Company Development Case</h3>
            </a>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi perspiciatis nam
              aspernatur porro</p>
            <a routerLink="/blog-details" class="line-bnt">Read More</a>
          </div>
        </div>
      </div>
    </div>

    <div class="blog-btn text-center">
      <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
    </div>
  </div>
</section> -->

<section class="home-contact-area bg-color ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <div class="section-title">
          <span>Contact Us</span>
          <h1>Let Us Know About Your Project Idea!</h1>
          <p>Share Your Project Idea and Let's Bring It to Life Together!</p>

          <ul class="footer-list foot-social">
            <li><a href="+91 9398739841"><i class="fa fa-phone" style="font-size:18px; top: -1px;"></i></a>+91
              9398739841</li>
            <li><a href="mailto:hello@isthara.com"><i class="fa fa-envelope" style="font-size:16px; top: -1px;"></i>
              </a> hello@isthara.com</li>
            <li><i class="fa fa-map-marker" style="font-size:18px"></i> The 27th building, Jayabheri enclave, Gachibowli
            </li>
          </ul>
        </div>

      </div>

      <div class="col-lg-6 col-md-6">
        <div class="content">
          <form id="contactForm">
            <div class="row">
              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                </div>
              </div>

              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                </div>
              </div>

              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <input type="text" name="phone_number" id="phone_number" required class="form-control"
                    placeholder="Your Phone" />
                </div>
              </div>

              <div class="col-lg-12 col-sm-12">
                <div class="form-group">
                  <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                    placeholder="Subject" />
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea name="message" class="form-control" id="message" cols="30" rows="5" required
                    placeholder="Your Message"></textarea>
                </div>
              </div>

              <div class="col-lg-12 col-md-12">
                <button type="submit" style="background-color: #844399; width: 80%; color: white; font-size: 16px;"
                  class="default-btn">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>
</section>