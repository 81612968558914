<!-- <header class="header-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-2 col-sm-0">
        <div class="logo">
          <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
        </div>
      </div>

      <div class="col-lg-8 col-sm-6 text-end pr-0">
        <div class="header-content-right">
          <ul class="header-contact">
            <li><a href="tel:+1123456789"><i class="bx bxs-phone-call"></i> +1 123 456 789</a></li>
            <li><a href="mailto:hello@aiva.com"><i class="bx bxs-envelope"></i> hello@aiva.com</a></li>
          </ul>
        </div>
      </div>

      <div class="col-lg-2 col-sm-6 text-end pl-0">
        <div class="header-content-right">
          <ul class="header-social">
            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
            <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
            <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header> -->

<div class="{{navbarClass}}">
  <div class="main-nav" ngStickyNav stickyClass="sticky-box-shadow">
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
        <a class="navbar-brand" routerLink="/">
          <img src="../../../../assets/images/common/logo.svg" alt="logo">
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse mean-menu" id="navbarNavDropdown">
          <ul class="navbar-nav text-left">
            <li class="nav-item">
              <a routerLink="/home" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>

            <li class="nav-item">
              <a routerLink="/about" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">About</a>
            </li>

            <li class="nav-item">
              <a routerLink="/solutions" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Solutions</a>
            </li>

            <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact </a></li>
          </ul>
        </div>
        <!-- <div class="nav-right">
          <form>
            <div class="input-group">
              <input type="text" class="form-control search" placeholder="Search..." />
            </div>

            <button type="submit">
              <i class="bx bx-search"></i>
            </button>
          </form>
        </div> -->
        <div class="nav-btn">
          <button routerLink="/contact" class="enquire-btn">Get In Touch</button>
        </div>
      </nav>
    </div>
  </div>
</div>