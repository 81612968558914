<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>About Us</h2>
      <ul>
        <li><a routerLink="/">Home </a></li>
        <li class="active">About Us</li>
      </ul>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="../../../../assets/images/about-us/shape-1.png" alt="shape" /></div>
    <div class="shape3"><img src="../../../../assets/images/about-us/shape-2.png" alt="shape" /></div>
    <div class="shape4"><img src="../../../../assets/images/about-us/shape-3.png" alt="shape" /></div>
    <div class="shape5"><img src="../../../../assets/images/about-us/shape-4.png" alt="shape" /></div>
    <div class="shape6"><img src="../../../../assets/images/about-us/shape-5.png" alt="shape" /></div>
  </div>
</div>

<section class="home-company-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="company-content">
          <div class="company-tittle">
            <span>About Us</span>
            <h2>Innovative IT Services for Your Growing Business</h2>
            <p>At Isthaara Tech Solutions, we believe that staying at the forefront of technology requires excellence in
              all aspects of IT. Our suite of services is designed to enhance the efficiency and effectiveness of your
              business operations, Our team of skilled professionals creates cutting-edge solutions customized to your
              unique needs, empowering you to stay ahead of your competitors.</p>
            <p>With our managed IT solutions, application development, and business analytics services, we enable you to
              take your business to the next level. We leverage our technical expertise to harness the potential of
              technology and shape a brighter future for our clients while creating long-term value. Our ultimate goal
              is to make a positive impact on society, take responsibility for our actions, and live up to our values.
            </p>

          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="company-img">
          <img src="../../../../assets/images/about-us/about-img-1.png" alt="company" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="technology-area ptb-100 bg-color">
  <div class="container">
    <div class="row align-items-center choose-c">
      <div class="col-lg-6">
        <div class="choose-img">
          <img src="../../../../assets/images/about-us/about-img-2.png" alt="choose" />

          <!-- <div class="technology-video">
            <a href="https://www.youtube.com/watch?v=TdSA7gkVYU0" class="video-btn popup-youtube"><i
                class='bx bx-play'></i></a>
          </div> -->
        </div>
      </div>

      <div class="col-lg-6">
        <div class="technology-content">
          <span>What we do</span>
          <h2>Building Better Business Solutions for a Better Tomorrow</h2>
          <p>Isthara Tech Solutions recognizes the importance of state-of-the-art technology and its positive effect on
            business growth. Our team of technical consultants and experts have extensive experience, and you can always
            count on us, whether you're a startup, mid-market business, or enterprise, to provide efficient and
            dependable business solutions.</p>
          <p>We are proud to have a select group of clients who rely on us to ensure uninterrupted progress for their
            projects and to deliver them to the best of our abilities. Our unwavering commitment to honesty, timely
            delivery, and excellence guarantees that our clients receive exceptional service at all times. By developing
            future-oriented digital solutions that help organizations achieve their objectives and broaden their
            horizons, we strive to make a positive impact on businesses worldwide.</p>
          <!-- <a routerLink="/contact" class="box-btn">Contact Us</a> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="feature-area ptb-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <div class="contnet">
          <div class="feature-tittle">
            <span style="color:#844399;">Our Values, Our Drivers</span>
            <h2>Delivering Excellence through Our Core Values and Driving Force </h2>
            <!-- <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt consectetur, beatae quod eaque
              reprehenderit non ab quibusdam doloribus voluptatibus! Voluptatum aspernatur quasi id dolore doloremque
              quo vero</p> -->
          </div>
          <ul>
            <li><img src="../../../../assets/images/about-us/checked-icon.png" alt=""> <span><b>Breaking Through
                  Barriers:</b>
                Creating Fearlessly at Isthaara Tech</span>
            </li>
            <li><img src="../../../../assets/images/about-us/checked-icon.png" alt=""> <span><b>Human-Centric Work
                  Culture:</b> Uplifting Others at Isthaara Tech
                Solutions</span>
            </li>
            <li><img src="../../../../assets/images/about-us/checked-icon.png" alt=""> <span><b>Integrity as a
                  Competitive
                  Advantage:</b> Isthaara Tech Solutions'
                Secret
                to Success</span></li>
            <li><img src="../../../../assets/images/about-us/checked-icon.png" alt=""> <span><b>Ownership as a Driver of
                  Success:</b> Isthaara Tech Solutions' Core
                Value</span>
            </li>
          </ul>
          <a routerLink="/contact" class="box-btn">Lets Talk!</a>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="feature-img">
          <img src="../../../../assets/images/about-us/about-img-3.png" alt="feature" />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <section class="client-area ptb-100 bg-color"> -->
  <!-- <div class="container">
    <div class="section-title">
      <span style="color:#844399;">Testimonials</span>
      <h2>What Our Client’s Say</h2>
       <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis
        exercitationem incidunt, alias corporis quam assumenda dicta.</p> -->
    <!-- </div> -->
    <!-- <div class="row client-wrap"> -->
      <!-- <owl-carousel-o [options]="clientWrap"> -->
      <!-- <ng-template carouselSlide> -->
      <!-- <div class="col-lg-4 col-md-12 single-client">
        <img src="../../../../assets/images/home/client-1.png" alt="Client">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum
          dolor sit amet,</p>
        <h3>Steven Jony</h3>
        <span>CEO of Company</span>
      </div> -->
      <!-- </ng-template>
        <ng-template carouselSlide> -->
      <!-- <div class="col-lg-4 col-md-12 single-client">
        <img src="../../../../assets/images/home/client-2.png" alt="img">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum
          dolor sit amet,</p>
        <h3>Omit Jacson</h3>
        <span>Company Founder</span>
      </div> -->
      <!-- </ng-template>

        <ng-template carouselSlide> -->
      <!-- <div class="col-lg-4 col-md-12 single-client">
        <img src="../../../../assets/images/home/client-2.png" alt="img">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
          dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum
          dolor sit amet,</p>
        <h3>Omit Jacson</h3>
        <span>Company Founder</span>
      </div> -->
      <!-- </ng-template>
      </owl-carousel-o> -->
    <!-- </div>
  </div> 
</section> -->