<footer class="footer-area pt-100">
  <div class="container">
    <div class="row footer-main-section">
      <div class="col-lg-4 col-md-6">
        <div class="content">
          <div class="logo">
            <a routerLink="/"><img src="../../../../assets/images/common/main_loader.svg" alt="logo" /></a>
          </div>
          <p>Technology-driven solutions for your business success.</p>

          <!-- <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div> -->

          <ul class="social">
            <li><a href="https://www.facebook.com/IstharaColiving/" target="_blank"><i class='fa fa-facebook'></i></a>
            </li>
            <li><a href="https://twitter.com/istharaliving?lang=en" target="_blank"><i class='fa fa-twitter'></i></a>
            </li>
            <li><a href="https://www.instagram.com/istharasharedliving/?igshid=13s4b9m0o276k" target="_blank"><i
                  class='fa fa-instagram'></i></a></li>
            <!-- <li><a href="https://in.pinterest.com/" target="_blank"><i class='fa fa-pinterest' ></i></a></li> -->
          </ul>
        </div>
      </div>

      <div class="col-lg-3 col-md-6">
        <div class="content ml-15">
          <h3>Our Service</h3>

          <ul class="footer-list services">
            <li><a routerLink="/">UI/UX Design</a></li>
            <li><a routerLink="/">Web Development</a></li>
            <li><a routerLink="/">Mobile App Development</a></li>
            <li><a routerLink="/">QA & Testing</a></li>
            <li><a routerLink="/">DevOps</a></li>
          </ul>
        </div>
      </div>

      <!-- <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/faq">FAQ</a></li>
                        <li><a routerLink="/solutions">Service</a></li>
                        <li><a routerLink="/">Career</a></li>
                        <li><a routerLink="/privecy">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                        <li><a routerLink="/">Data Analysis</a></li>
                    </ul>
                </div>
            </div> -->

      <div class="col-lg-3 col-md-6">
        <div class="content contacts">
          <h3 class="ml-40">Contact</h3>

          <ul class="footer-list foot-social">
            <li><a href="+91 9398739841"><i class="fa fa-phone"></i></a>+91
              9398739841</li>
            <li><a href="mailto:hello@isthara.com"><i class="fa fa-envelope"></i>
              </a> hello@isthara.com</li>
            <li><i class="fa fa-map-marker"></i> The 27th building, Jayabheri enclave, Gachibowli
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="copy-area">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <ul class="menu">
            <li><a routerLink="/home" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Home</a></li>
            <li><a routerLink="/solutions" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Solutions</a></li>
            <li><a routerLink="/about" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">About</a></li>
            <li><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
          </ul>
        </div>

        <!-- <div class="col-lg-6">
                    <p>© Aiva is Proudly Owned by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
                </div> -->
      </div>
    </div>
  </div>
</footer>

<div class="go-top">
  <i class='fa fa-chevrons-up'></i>
  <i class='fa fa-chevrons-up'></i>
</div>