import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { ConstMaterial } from './components/constant-material.module';
import { CountdownModule } from 'ngx-countdown';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { HomePageComponent } from './components/pages/home-page/home-page.component';
import { SolutionsPageComponent } from './components/pages/solutions-page/solutions-page.component';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { TabsModule } from 'ngx-tabset';
import { AccordionModule } from "ngx-accordion";
import { FooterComponent } from './components/common/footer/footer.component';
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    AboutUsComponent,
    ContactUsComponent,
    SolutionsPageComponent,
    NavbarComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ConstMaterial,
    HttpClientModule,
    CommonModule,
    CountdownModule,
    NgOtpInputModule,
    CarouselModule,
    TabsModule,
    AccordionModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
