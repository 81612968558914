<div class="page-title-area">
  <div class="container">
    <div class="page-title-content">
      <h2>Solutions</h2>
      <ul>
        <li><a routerLink="/">Home </a></li>
        <li class="active">Solutions</li>
      </ul>
    </div>
  </div>

  <div class="page-shape">
    <div class="shape1"><img src="../../../../assets/images/solution-page/solution-1.svg" alt="shape" /></div>
    <div class="shape3"><img src="../../../../assets/images/solution-page/solution-2.svg" alt="shape" /></div>
    <div class="shape4"><img src="../../../../assets/images/solution-page/solution-3.svg" /></div>
    <div class="shape5"><img src="../../../../assets/images/solution-page/solution-4.svg" /></div>
    <div class="shape6"><img src="../../../../assets/images/solution-page/solution-5.svg" alt="shape" /></div>
  </div>
</div>

<section class="home-service-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span class="section-main-heading">Isthara Tech Smart Services</span>
      <div class="section-heading">Comprehensive Tech Solutions for Your Business Needs</div>
      <div class="home-section-text"> Our team will leverage the latest technologies and industry best practices to build a solution that addresses
        your unique business challenges and drives growth.</div>
    </div>

    <div class="row services-box-container">
      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/solution-page/ui-ux.svg" alt="UI/UX_svg" />
          </div>

          <div class="service-content">
            <div class="service-heading">UI/UX Design</div>
            <div class="service-text">We provide UX-focused product ideation and design services, using a comprehensive approach that includes
              brainstorming, design thinking, UX research, and product strategy to create engaging UI designs aligned
              with our clients' goals.
            </div>
            <a routerLink="/solutions-details" class="service-button">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/solution-page/web-development.svg" alt="web_svg" />
          </div>

          <div class="service-content">
            <div class="service-heading">Web Development</div>
            <div class="service-text">Our web development services provide tailored solutions for our clients, utilizing a user-centered
              approach that includes strategy, design, and development. We use the latest technology and best practices
              to build engaging, fully functional websites that are optimized for speed, security, and search engine
              visibility.
            </div>
            <a routerLink="/solutions-details" class="service-button">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/solution-page/qa-testing.svg" alt="qa_svg" />
          </div>

          <div class="service-content">
            <div class="service-heading">QA Testing</div>
            <div class="service-text">Our QA testing service ensures high-quality products, including web and mobile apps, through rigorous
              testing of functionality, usability, security, and performance. We use industry-standard testing
              methodologies for efficient and comprehensive results. Our approach enables us to identify and resolve
              issues quickly.
            </div>
            <a routerLink="/solutions-details" class="service-button">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/solution-page/mobile-app.svg" alt="mobile_svg" />
          </div>

          <div class="service-content">
            <div class="service-heading">Mobile App Development</div>
            <div class="service-text">Our mobile app development service provides customized solutions to meet our clients' needs, utilizing a
              user-centered approach that includes strategy, design, and development. We use the latest technology and
              best practices to build engaging, fully functional mobile apps that are optimized for performance and
              security.
            </div>
            <a routerLink="/solutions-details" class="service-button">Connect Now</a>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-sm-6">
        <div class="single-service">
          <div class="service-img">
            <img src="../../../../assets/images/solution-page/devops.svg" alt="devops_svg" />
          </div>

          <div class="service-content">
            <div class="service-heading"> DevOps</div>
            <div class="service-text">we partner as your trusted advisors to assess current state with respect to cloud & DevOps, Recommend the
              most valuable path, and guide throughout the transformation journey. we provide end to end cloud
              professional services across the azure, aws, & GCP. we build manage, optimize the cloud infrastructure and
              solutions. rephrase this sentence into short.
            </div>
            <a routerLink="/solutions-details" class="service-button">Connect Now</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>