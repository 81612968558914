import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'techIsthara';
  loadingRouteConfig: boolean;
  constructor(private router: Router) { }
  ngOnInit(): void {
    this.loaderForMainModules();
  }

  loaderForMainModules() {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      } else if (event instanceof NavigationCancel) {
        this.loadingRouteConfig = false;
      } else if(event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
}
