import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [
    Location, {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
export class NavbarComponent implements OnInit {

  location: any;
  navbarClass: any;

  classApplied = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.location = this.router.url;
          if (this.location == '/home-three') {
            this.navbarClass = 'navbar-area three';
          } else {
            this.navbarClass = 'navbar-area';
          }
        }
      });
  }

  toggleClass() {
    this.classApplied = !this.classApplied;
  }
}
